<template>
  <v-card>
    <map-radius :list="locationList"></map-radius>
  </v-card>
</template>

<script>
import MapRadius from "../components/MapRadius";
import { PORTAL_CONFIG } from "../config";

export default {
  name: "assets",
  components: { MapRadius },
  data() {
    return {
      locationList: []
    };
  },
  created() {
    //this.loadData();
  },

  methods: {
    loadData() {
      fetch(`${PORTAL_CONFIG.SERVER_WEB}/dev/where.php`)
        .then(response => {
          return response.json();
        })
        .then(result => {
          //this.locationList = result;
          if (this.$route.params.assetID === 31) {
            this.locationList = result.filter(el => el.id2 === 0);
          } else {
            this.locationList = result.filter(
              el => el.id2 === this.$route.params.assetID
            );
          }
        });
    }
  },
  watch: {
    $route() {
      //this.loadData();
    }
  }
};
</script>
